//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PartnerHeader from '@/components/common/header/PartnerHeader'
import AboutSection from '@/components/partner/AboutSection'
import scroll from '@/mixins/scroll'
import { mapState } from 'vuex'

import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'StartSection',
  components: {
    PartnerHeader,
    AboutSection,
    LazyHydrate,
  },
  mixins: [scroll],
  data() {
    return {
      arr: ['frame-creative.png', 'frame-pyt.png', 'frame-math.png'],
      arrIndex: 0,
    }
  },
  computed: {
    ...mapState(['i18n']),
    isSnakeAllowed() {
      return this.i18n.locale === 'ru'
    },
  },
  mounted() {
    this.initSlider()
    if (!this.isSnakeAllowed) {
      this.arr[1] = 'frame-pyt-nosnake.png'
    }
  },
  methods: {
    initSlider() {
      const img = document.getElementsByClassName('start-section__img')[0]
      img.style.opacity = '1'
      const slideDelay = 6 * 1000
      const showSlideTime = 4.75 * 1000
      const hideSlideTime = 1.25 * 1000
      const changeOpacity = () => {
        if (img.style.opacity === '1') {
          img.style.opacity = '0'
        } else {
          img.style.opacity = '1'
        }
      }
      const start = (delay) => {
        setTimeout(() => {
          changeOpacity()
          setTimeout(() => {
            changeOpacity()
          }, hideSlideTime)
        }, showSlideTime)
        setTimeout(() => {
          if (this.arrIndex === this.arr.length - 1) {
            this.arrIndex = 0
          } else {
            this.arrIndex++
          }
          start(slideDelay)
        }, delay)
      }
      start(slideDelay)
    },
  },
}
