//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AboutSection',
  data() {
    return {
      aboutList: [
        {
          img: 'about1.png',
          text: this.$getTextSetting('firstScreenBlockItem1'),
        },
        {
          img: 'about2.png',
          text: this.$getTextSetting('firstScreenBlockItem2'),
        },
        {
          img: 'about3.png',
          text: this.$getTextSetting('firstScreenBlockItem3'),
        },
        {
          img: 'about4.png',
          text: this.$getTextSetting('firstScreenBlockItem4'),
        },
      ],
    }
  },
}
